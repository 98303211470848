/* eslint-disable consistent-return */
/** @namespace Pwa/Component/Config/waitForElm */
export const waitForElm = (selector) => new Promise((resolve) => {
    if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
    });
});

/** @namespace Pwa/Component/Config/waitForAllElm */
export const waitForAllElm = (selector) => new Promise((resolve) => {
    const elements = document.querySelectorAll(selector);

    if (elements.length > 0) {
        return resolve(Array.from(elements)); // Restituisce tutti gli elementi trovati come array
    }

    const observer = new MutationObserver(() => {
        const elements = document.querySelectorAll(selector);

        if (elements.length > 0) {
            resolve(Array.from(elements)); // Risolviamo con tutti gli elementi trovati
            observer.disconnect();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
    });
});

/** @namespace Pwa/Component/Config/awaitSetState */
export const awaitSetState = async (that, object) => new Promise((resolve) => {
    that.setState({ ...object }, resolve);
});
